.people-mini {
  width: 32px;
  cursor: pointer; }

.people-mini-img {
  width: 32px;
  height: 64px; }

.people-mini-button {
  display: none; }

.people-mini:hover {
  width: 64px; }

.people-mini:hover .people-mini-img {
  width: 64px;
  height: 128px; }

.people-mini:hover .people-mini-button {
  display: initial; }

.tabs-audits-reid {
  border-bottom: 3px solid #eee;
  margin-bottom: -3px !important; }

.tabs-audits-reid.is-boxed a:hover {
  border-bottom-color: #00d1b2 !important;
  border-bottom-width: 5px; }

.tabs-audits-reid.is-boxed a {
  border-bottom-width: 5px; }

.tabs-audits-reid.is-boxed a.is-active {
  border-bottom-width: 5px; }

.manualcount-frametocount {
  border: 1px solid #eee;
  padding: 1px 1px 1px 1px;
  border-radius: 5px 5px 0px 0px;
  border-bottom: 3px solid #eee;
  cursor: pointer; }

.manualcount-frametocount:hover {
  border-bottom: 3px solid #00d1b2;
  background-color: whitesmoke; }

.manualcount-frametocount.is-disabled {
  background-color: whitesmoke;
  border: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-radius: 5px; }

.manualcount-frametocount.is-selected {
  border-bottom: 3px solid #00d1b2; }

.manualcount-review {
  border: 1px solid #eee;
  padding: 5px 20px 5px 20px;
  border-radius: 5px 5px 0px 0px;
  border-bottom: 3px solid #eee;
  cursor: pointer; }

.manualcount-review:hover {
  border-bottom: 3px solid #00d1b2;
  background-color: whitesmoke; }

.manualcount-review.is-disabled {
  background-color: whitesmoke;
  border: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-radius: 5px; }

.manualcount-review.is-selected {
  border-bottom: 3px solid #00d1b2; }
