.manualcount-daytocount {
  border: 1px solid #eee;
  padding: 5px 20px 5px 20px;
  border-radius: 5px 5px 0px 0px;
  border-bottom: 3px solid #eee;
  cursor: pointer; }

.manualcount-daytocount:hover {
  border-bottom: 3px solid #00d1b2;
  background-color: whitesmoke; }

.manualcount-daytocount.is-disabled {
  background-color: whitesmoke;
  border: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-radius: 5px;
  cursor: initial; }

.manualcount-daytocount.is-selected {
  border-bottom: 3px solid #00d1b2; }

.manualcount-hourtocount {
  border: 1px solid #eee;
  padding: 5px 20px 5px 20px;
  border-radius: 5px;
  cursor: pointer; }

.manualcount-hourtocount:hover {
  border-bottom: 1px solid #00d1b2; }

.manualcount-hourtocount.is-disabled {
  background-color: whitesmoke;
  border: 1px solid #ccc;
  cursor: initial; }

.manualcount-hourtocount.is-selected {
  background-color: rgba(0, 209, 178, 0.1); }

.card-audits {
  box-shadow: none !important;
  border: 1px solid rgba(10, 10, 10, 0.1);
  border-radius: 0px 0px 4px 4px !important;
  border-top: 0px; }

.tabs-audits {
  border-bottom: 3px solid #eee;
  margin-bottom: -3px !important; }

.tabs-audits.is-boxed a:hover {
  border-bottom-color: #00d1b2 !important;
  border-bottom-width: 5px; }

.tabs-audits.is-boxed a {
  border-bottom-width: 5px; }

.tabs-audits.is-boxed a.is-active {
  border-bottom-width: 5px; }

.audit-table-detail-cel-text {
  overflow: hidden;
  text-overflow: ellipsis; }

.audit-table-detail-cel-number {
  text-align: right; }

.audit-imagebox {
  width: 704px;
  height: 510px;
  margin-bottom: 1em;
  border-radius: 4px; }
  .audit-imagebox svg {
    border: 1px solid #7e7e7e; }
