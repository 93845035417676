.box-analysis-tags {
  border-radius: 5px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between; }

.tags-analysis-buttons {
  align-items: center;
  border-top: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  padding: 5px; }

.tags-analysis {
  overflow: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 5px;
  text-overflow: ellipsis; }

.tags-analysis .tag {
  padding: 10px;
  border: 2px solid #fff;
  font-size: 0.8em;
  margin: 1px;
  cursor: pointer;
  border-radius: 10px;
  max-width: 100%;
  justify-content: left;
  overflow: hidden; }

.tag.button {
  max-width: 100%;
  height: 100%; }

.tag.button {
  justify-content: center; }

.box-tags {
  padding: 10px; }

.tag-analysis .noshow-button {
  display: none; }

.tag-analysis:hover .noshow-button {
  cursor: pointer;
  margin-left: 0px; }

.tag-selected {
  border: 2px solid #222222 !important; }

.box-tags-audit-sugestions {
  display: flex;
  flex-direction: column;
  height: 45%;
  overflow: auto;
  border-radius: 4px; }

.box-tags-audit-sugestion {
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between; }

.box-tags-audit-sugestion:hover {
  background-color: #eee; }

.box-tags-audit-sugestion-tag {
  padding: 2px;
  background-color: #fff;
  margin: 5px;
  margin: 5; }
