
.sensor-card-title-content {
  width: 100%;
  font-weight: bold;
  font-size: 0.95rem!important;
}

.sensor-card-smile {
  padding: 1rem 1rem 1rem 1rem !important;
  box-shadow: 3px 2px 8px #cccccc;
}

.sensorcardHora {
  font-size: 1rem!important;
}

.sensorcardTemperatura-title {
  font-weight: bold;
  font-size: .6rem!important;
}

.sensorcardTemperatura-value {
  font-size: 1rem!important;
}

.sensorcardHumidade {
  font-size: .7rem!important;
}